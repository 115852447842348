import { config } from "../../config";

const MONTHLY_SUBSCRIPTION_LINK = config.links.streamRegistration + '?subscribe_after=3'
const YEARLY_SUBSCRIPTION_LINK = config.links.streamRegistration + '?subscribe_after=4'

const Presale = () => (
    <div className="presale-container" id="presale">
        <div className="bg"></div>
        <div className="info">
            <div className="title">
                Choose the plan that’s right<br/>
                <span className="emphase">for you</span>
            </div>
        </div>
        <div className="subscriptions">
            <div className="subscription-item monthly">
                <a href={MONTHLY_SUBSCRIPTION_LINK}>
                    <div className="cover">
                        <div className="price">$5.99</div>
                    </div>
                </a>
                <div className="headline">
                    <div className="title">Monthly Subscription</div>
                    <div className="renew-info">Renews every month</div>
                </div>
                <div className="description">
                    Enjoy the new era of REALITY TV anytime and anywhere, with exclusive access to your favorite new
                    shows each month.
                </div>
            </div>
            <div className="subscription-item yearly">
                <a href={YEARLY_SUBSCRIPTION_LINK}>
                    <div className="cover">
                        <div className="price">$49.99</div>
                        <div className="old-price">26% off</div>
                    </div>
                </a>
                <div className="headline">
                    <div className="title">Yearly Subscription</div>
                    <div className="renew-info">Renews every year</div>
                </div>
                <div className="description">
                    Why wait? Secure your spot for a whole year, where we are ushering in the
                    new era of REALITY TV.
                </div>
            </div>
        </div>
    </div>
)

export default Presale
