import { useEffect, useRef } from "react";
import BtvLogoLong from '../../assets/brandon-tv-logo.svg'
import videoBgDesktop from "../../assets/main-bg.jpg";
import { config } from "../../config";
import Stores from "../../components/Stores";

const videoDesktop = 'https://storage.googleapis.com/brandon-app-prod/static/btv-trailer.mp4'
const videoMobile = 'https://storage.googleapis.com/brandon-app-prod/static/btv-trailer.mp4'

const mobileWidth = 768

const MainScreen = () => {
    const [windowWidth, windowHeight] = useRef([window.innerWidth, window.innerHeight]).current
    const videoRef = useRef(null)
    const bgVideo = windowWidth < mobileWidth ? videoMobile : videoDesktop
    const bgPoster = videoBgDesktop
    if (windowWidth < mobileWidth) {
        document.documentElement.style.setProperty('--app-screen-height', `${windowHeight}px`)
    }

    useEffect(() => {
        if (videoRef) {
            videoRef.current.play()
        }
    }, [videoRef])

    return (
        <div className="main">
            <div className="videoBG">
                <video ref={videoRef}
                       className="videoElement"
                       poster={bgPoster}
                       preload="auto"
                       muted="muted"
                       loop="loop"
                       playsInline="playsinline"
                >
                    <source src={bgVideo} type="video/mp4"/>
                </video>
            </div>
            <div className="content">
                <div className="logo"><img className="img" src={BtvLogoLong} alt="Brandon TV Logo"/></div>
                <div className="description">
                    Bringing some of the most iconic reality stars back to television on our brand new streaming app.
                </div>
                <a href={config.links.streamRegistration} className="btn">Stream now</a>
                <div className="availability">
                    <div className="available-text">Watch on:</div>
                    <div className="platforms">
                        {/*<a href={config.stores.apple} ><div className="platform apple"></div></a>*/}
                        {/*<a href={config.stores.google} ><div className="platform android"></div></a>*/}
                        {/*<a href={config.stores.googleTv} ><div className="platform android-tv"></div></a>*/}
                        {/*<a href={config.stores.roku} ><div className="platform roku"></div></a>*/}
                        <div className="platform apple"></div>
                        <div className="platform android"></div>
                        <div className="platform android-tv"></div>
                        <div className="platform roku"></div>
                        {/*<div className="platform web"></div>*/}
                        {/*<div className="platform apple"></div>*/}
                        {/*<div className="platform android"></div>*/}
                        {/*<div className="platform roku"></div>*/}
                    </div>
                    <Stores/>
                </div>
            </div>
        </div>
    )
}

export default MainScreen
